<template>
  <div class="p-t-10">
    <Row>
      <i-col span="24">
        <span class="title">名称 </span>
        <Input v-model="agreementBean.name" placeholder="请输入确认单名称..." size="small" style="width: 300px" />
        <span v-show="validName" class="text-red">请设置确认单名称！</span>
      </i-col>
    </Row>
    <Row class="p-t-10">
      <i-col span="24">
        <span class="title" >客户 </span>
        <i-select v-model="chooseAdvertiserId" :disabled="isEdit" size="small"  style="width:300px" clearable>
          <i-option
            v-for="advertiser in advertisers"
            :key="advertiser.advertiserId"
            :value="advertiser.advertiserId"
          >{{advertiser.advertiserName}}</i-option>
        </i-select>
        <span v-show="validAdvertiser" class="text-red">请选择当前确认单所涉及到的客户！</span>
      </i-col>
    </Row>
    <Row class="p-t-10 m-b-5">
      <i-col span="24">
        <span>设置当前确认单所包含的订单方案：</span>
        <a  @click="handleOpenOrderPicker">添加订单</a>
        <span v-show="validOrder" class="text-red">请选择当前确认单所包含的订单！</span>
      </i-col>
    </Row>
    <!-- 当前确认单所包含的订单清单 -->
    <i-table stripe  :data="agreementOrders" :columns="titles"></i-table>
    <!-- 费用汇总 -->
    <price-summary :productsPrice="priceOrder"></price-summary>

    <Row class="p-t-10">
      <i-col span="24">
        <span>描述备注：</span>
        <Input v-model="agreementBean.desc" type="textarea" placeholder="请输入确认单备注信息..." />
      </i-col>
    </Row>
    <div class="p-t-10">
      <Button type="info" @click="handleSubmit(1)" :loading="loadingSubmit">保存为草稿</Button>
      <Button
        type="success"
        :disabled="!canSubmit"
        class="m-l-10"
        @click="handleSubmit(2)"
        :loading="loadingSubmit"
      >确认并提交审批</Button>
      <span v-show="!canSubmit" class="text-orange m-l-5">请配置完所有订单的执行价</span>
    </div>

    <!-- 弹窗选中当前确认单所包含的订单 -->
    <Modal v-model="showOrdersPicker" :width="800" footer-hide>
      <div slot="header">
        <p>当前可以选择的订单</p>
      </div>
      <orders-picker
        v-if="showOrdersPicker"
        :params="agreementParms"
        :existedOrders="agreementOrders"
        @on-chosen="handleOrdersChosen"
      ></orders-picker>
    </Modal>
    <!-- 弹窗配置订单执行价 -->
    <price-setting
      :orderId="chooseOrderId"
      :priceMap="priceMap"
      @on-updated="handlePriceUpdated"
      :isShow.sync="showPriceSettingModal"
    ></price-setting>
  </div>
</template>

<script>
import ordersPicker from '@/components/contract/contractDetail/common/OrdersPicker'
import priceSetting from '@/components/agreement/common/priceSetting'
import priceSummary from '@/components/agreement/common/priceSummary'

import { getOrderAdvertiser, getOrderList } from '@/api/order/order'
import { saveConfirmation, getConfirmation } from '@/api/order/confirmation'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    baseId: {
      type: Number,
      default: -1
    }
  },
  components: {
    ordersPicker,
    priceSetting,
    priceSummary
  },
  data () {
    return {
      userId: this.$store.getters.token.userInfo.userId,
      advertisers: [],
      chooseAdvertiserId: 0,
      showOrdersPicker: false,
      validName: false,
      validAdvertiser: false,
      validOrder: false,
      agreementBean: {
        confirmationId: 0,
        desc: '',
        name: '',
        orderIds: '',
        productfees: '',
        submit: false
      },
      agreementOrders: [], // 选中的订单集合
      titles: [
        { title: '投放品牌', key: 'brandName' },
        { title: '备注', key: 'remark' },
        // {
        //   title: '投放档期',
        //   render: (h, data) => {
        //     return h(
        //       'span',
        //       GetCurrentSchedule(data.row.startDate, data.row.endDate)
        //     )
        //   }
        // },
        // {
        //   title: '档期天数',
        //   render: (h, params) => {
        //     return h('span', params.row.tradePublishday + '天')
        //   }
        // },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            data.row.purchasedScheduleList.forEach(item => {
              spans.push(h('span', GetCurrentSchedule(item.startDate, item.endDate)))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '采购资源统计',
          width: 150,
          render: (h, params) => {
            const spans = []
            params.row.orderProductQuantityBeanList.forEach((resource) => {
              spans.push(h('span', resource.productName))
              spans.push(
                h(
                  'span',
                  {
                    attrs: {
                      class: 'text-orange text-12'
                    }
                  },
                  resource.quantity
                )
              )
              spans.push(h('span', resource.unitName))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            return h('div', [
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.handlePriceSetting(data.row)
                    }
                  }
                },
                '配置执行价'
              ),
              h(
                'a',
                {
                  style: {
                    'margin-left': '5px'
                  },
                  on: {
                    click: () => {
                      this.handleDelete(data.row)
                    }
                  }
                },
                '删除'
              )
            ])
          }
        }
      ],
      chooseOrderId: 0, // 订单集合中选择设置的订单ID
      showPriceSettingModal: false, // 显示价格设置窗口
      priceMap: new Map(), // 装载全局的价格设置
      priceOrder: [],
      loadingSubmit: false
    }
  },
  created () {
    this.agreementBean.confirmationId = this.baseId
    this.initPageData()
  },
  methods: {
    initPageData () {
      getOrderAdvertiser({ userId: this.userId, status: 0 })
        .then((res) => {
          this.advertisers = res
        })
        .then(() => {
          if (this.isEdit) {
            this.initModifyPage()
          } else {
            // 重新初始化所有数据
            this.agreementBean.desc = ''
            this.agreementBean.name = ''

            this.priceMap = new Map()
            this.agreementOrders = []
            this.chooseAdvertiserId = 0
            this.chooseOrderId = 0
            this.priceOrder = []
          }
        })
    },
    initModifyPage () {
      const query = { confirmationId: this.baseId }
      getConfirmation(query).then((res) => {
        this.agreementBean.desc = res.desc
        this.agreementBean.name = res.name

        this.chooseAdvertiserId = res.advertiserId

        this.priceMap = new Map()
        res.orderIdList.forEach((orderId) => {
          this.priceMap.set(orderId, {
            orderId: orderId,
            settingProductfees: res.confirmationItemVOList.filter(
              (x) => x.orderId === orderId
            )
          })
        })
        this.priceOrder = [...this.priceMap.values()]

        // 批量获取已经选中的订单列表
        getOrderList({ orderIdStr: JSON.stringify(res.orderIdList) }).then(
          (orders) => {
            this.agreementOrders = orders
          }
        )
      })
    },
    handleOpenOrderPicker () {
      if (
        this.chooseAdvertiserId === 0 ||
        this.chooseAdvertiserId === undefined
      ) {
        this.validAdvertiser = true
      } else {
        this.validAdvertiser = false
        this.agreementParms = {
          userId: this.userId,
          advertiserResultBean: { id: this.chooseAdvertiserId }
        }

        this.showOrdersPicker = true
      }
    },
    handleOrdersChosen (orders) {
      this.agreementOrders = orders
    },
    handlePriceSetting (order) {
      // 弹窗设置订单执行价
      this.chooseOrderId = order.id
      this.showPriceSettingModal = true
    },
    handlePriceUpdated (data) {
      this.showPriceSettingModal = false
      this.priceMap.set(data.orderId, data)
      this.priceOrder = [...this.priceMap.values()]
    },
    handleDelete (order) {
      const index = this.agreementOrders.findIndex((o) => o.id === order.id)
      this.agreementOrders.splice(index, 1)
      this.priceMap.delete(order.id)
      this.priceOrder = [...this.priceMap.values()]
    },
    handleSubmit (type) {
      this.agreementBean.orderIds = JSON.stringify(
        this.agreementOrders.map((x) => x.id)
      )
      this.validName = false
      this.validOrder = false
      this.validAdvertiser = false

      if (this.agreementBean.name === '') {
        this.validName = true
        return
      } else if (this.agreementOrders.length === 0) {
        this.validOrder = true
        return
      }

      const productfees = []
      this.priceOrder.forEach((order) => {
        order.settingProductfees.forEach((fees) => {
          productfees.push({
            endDate: fees.endDate,
            goodsId: fees.goodsId,
            goodsName: fees.goodsName,
            productType: fees.productType,
            orderId: order.orderId,
            startDate: fees.startDate,
            usePrice: fees.usePrice,
            installPrice: fees.installPrice,
            producePrice: fees.producePrice
          })
        })
      })
      this.agreementBean.productfees = JSON.stringify(productfees)
      this.agreementBean.submit = type === 2

      this.loadingSubmit = true
      saveConfirmation(this.agreementBean).then((res) => {
        if (res && res.confirmationId && !res.errcode) {
          if (this.isEdit) {
            this.$Notice.success({ desc: '确认单保存成功' })
          } else {
            this.$Notice.success({ desc: '确认单创建成功' })
          }

          this.$emit('on-submit')
        } else {
          this.$Notice.error({ desc: res.errmsg })
        }
        this.loadingSubmit = false
      })
    }
  },
  computed: {
    isEdit () {
      return this.baseId !== -1
    },
    canSubmit: function () {
      const orderIds = this.agreementOrders.map((x) => x.id)
      const priceOrderIds = this.priceOrder.map((x) => x.orderId)
      if (this.agreementOrders.length < 1) {
        return false
      }
      if (this.agreementOrders.length !== this.priceOrder.length) {
        return false
      }

      const amountItem = this.priceOrder.map(
        (x) => x.settingProductfees.length
      )
      let totalItem = 0
      amountItem.forEach((element) => {
        totalItem += element
      })
      if (totalItem === 0) {
        return false
      }
      return orderIds.every((x) => priceOrderIds.includes(x))
    }
  },
  watch: {
    baseId (val) {
      this.agreementBean.confirmationId = val
      this.initPageData()
    }
  }
}
</script>
