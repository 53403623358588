<template>
    <Modal v-model="showModal" :width="1000" :mask-closable="false" >
      <p slot="header" class="text-center" style="color:#FA8B59">
          <Icon type="logo-yen" />
          <span>配置订单金额</span>
      </p>
      <h4>订单基础信息</h4>
      <Row>
        <i-col span="12">发布品牌：{{orderBasic.brandName}}</i-col>
        <i-col span="12">订单备注：{{orderBasic.remark}}</i-col>
      </Row>
      <Row class="p-b-5">
        <i-col span="12">发布日期：<Tag type="border" color="black">{{orderBasic.startDate}} 至 {{orderBasic.endDate}} </Tag></i-col>
        <i-col span="12">创建时间：{{orderBasic.createTime}}</i-col>
      </Row>
      <h4>增值服务</h4>
      <Row class="p-b-5">
        <i-col span="8">
            定位服务：
            <Icon v-if="orderBasic.fixProtect" color="green" size="20" type="md-checkbox-outline" />
            <span v-else>无</span>
        </i-col>
        <i-col span="8">
            不确认方案：
            <Icon v-if="!orderBasic.fixed" color="green" size="20" type="md-checkbox-outline" />
            <span v-else>无</span>
        </i-col>
        <i-col span="8">
            行业保护：
            <template v-if="orderBasic.industryProtect">
                <Icon color="green" size="20" type="md-checkbox-outline" />
                {{orderBasic.industryProtectName}}
            </template>
            <span v-else>无</span>
        </i-col>
      </Row>

      <h4 class="hrHead">设置媒体执行价</h4>
      <Row class="table-header text-center">
        <i-col span="5">产品名称</i-col>
        <i-col span="3">发布档期</i-col>
        <i-col span="3">采购数量</i-col>
        <i-col span="4">刊例价</i-col>
        <i-col span="3">执行价</i-col>
        <i-col span="3">制作费</i-col>
        <i-col span="3">上下刊费</i-col>
      </Row>
      <Row type="flex" justify="center" align="top"  class="text-center" style="margin:10px" v-for="(product,i) in productPriceMap" :key="i">
        <i-col span="5">{{product.goodName}}</i-col>
        <i-col span="3">{{getSchedule(product)}}</i-col>
        <i-col span="3">
            {{product.normalQuantity}} {{product.productUnit}}
        </i-col>
        <i-col span="4">
            {{formatMoney(product.normalUsePrice)}}
        </i-col>
        <i-col span="3"> <!--  执行价 -->
            <InputNumber v-model="product.settingSettlementPrice"  @on-blur="invalidSellNumber(product)" :min="0" size="small"></InputNumber>
            <Icon type="md-refresh" color="green" style="cursor: pointer;" @click="refreshPrice(product)"/>
        </i-col>
        <i-col span="3"> <!--  制作费 -->
            <InputNumber v-model="product.settingProducePrice"  @on-blur="invalidProduceNumber(product)" :min="0" size="small"></InputNumber>
        </i-col>
        <i-col span="3"> <!--  上下刊费 -->
            <InputNumber v-model="product.settingInstallPrice" @on-blur="invalidInstallNumber(product)" :min="0" size="small"></InputNumber>
        </i-col>
      </Row>

      <div slot="footer">
        <i-button type="primary" @click="handleConfirm">确认</i-button>
        <i-button class="btn-black" @click="handleCancel">关闭</i-button>
      </div>

      <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>加载中...</div>
      </Spin>
    </Modal>
</template>

<script>
import { getOrder } from '@/api/order/order'
import { getorderplanprice } from '@/api/scp/contractorder'

import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    orderId: {
      type: Number
    },
    isShow: {
      type: Boolean,
      required: true
    },
    priceMap: Map
  },
  data () {
    return {
      orderBasic: { }, // 订单基本信息
      showModal: false,
      productPriceMap: [], // 产品价格设置
      spinShow: false
    }
  },
  methods: {
    getDetail () {
      // 获取当前订单的基本信息
      getOrder({ orderId: this.orderId }).then(res => {
        this.orderBasic = res
      })

      // 获取当前订单内产品的刊例价和最低结算价
      getorderplanprice({ orderId: this.orderId }).then(products => {
        const setPriceHistory = this.priceMap.get(this.orderId)

        this.productPriceMap = products.map(p => {
          const priceSetting = {}

          if (setPriceHistory && setPriceHistory.settingProductfees.find(x => x.goodsId === p.goodId && x.startDate === p.startDate && x.endDate === p.endDate)) {
            const historyPriceSeting = setPriceHistory.settingProductfees.find(x => x.goodsId === p.goodId && x.startDate === p.startDate && x.endDate === p.endDate)

            priceSetting.settingSettlementPrice = historyPriceSeting.usePrice
            priceSetting.settingInstallPrice = historyPriceSeting.installPrice
            priceSetting.settingProducePrice = historyPriceSeting.producePrice
          } else {
            priceSetting.settingSettlementPrice = p.normalSettlementPrice
            priceSetting.settingInstallPrice = p.totalInstallPrice
            priceSetting.settingProducePrice = p.totalProducePrice
          }

          return Object.assign({}, p, priceSetting)
        })
        this.spinShow = false
      })
    },
    handleConfirm () {
      const settingProductfees = []

      this.productPriceMap.forEach(product => {
        settingProductfees.push({
          endDate: product.endDate,
          goodsId: product.goodId,
          productType: product.productType,
          goodsName: product.goodName,
          orderId: product.orderId,
          startDate: product.startDate,
          quantity: product.normalQuantity, // 采购数量
          productUnit: product.productUnit,
          tusePrice: product.normalUsePrice, // 执行价
          usePrice: product.settingSettlementPrice,
          installPrice: product.settingInstallPrice,
          producePrice: product.settingProducePrice
        })
      })

      this.$emit('on-updated', { orderId: this.orderId, settingProductfees: settingProductfees })
    },
    handleCancel () {
      this.showModal = false
    },
    formatMoney (number) {
      return toMoney(number)
    },
    getSchedule (product) {
      return GetCurrentSchedule(product.startDate, product.endDate)
    },
    refreshPrice (product) {
      product.settingSettlementPrice = product.normalSettlementPrice
    },
    invalidSellNumber (produce) {
      if (!produce.settingSettlementPrice || produce.settingSettlementPrice === '') {
        produce.settingSettlementPrice = 0
      }
    },
    invalidProduceNumber (produce) {
      if (!produce.settingProducePrice || produce.settingProducePrice === '') {
        produce.settingProducePrice = 0
      }
    },
    invalidInstallNumber (produce) {
      if (!produce.settingInstallPrice || produce.settingInstallPrice === '') {
        produce.settingInstallPrice = 0
      }
    }
  },
  watch: {
    showModal (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.showModal = val
      if (val) {
        this.productPriceMap = []
        this.spinShow = true
        this.getDetail()
      }
    }
  }
}
</script>
